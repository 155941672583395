import React from 'react'

import FullScreenScroll from '@/components/FullScreenScroll/Index'

// import Carousel from './carousel/CustomCarousel'
import List from './List'

const GameCenter = () => {
  // return (
  //   <div className="gameCenterWrap">
  //     <List isActive={true} />
  //   </div>
  // )
  return (
    <FullScreenScroll
      scrollKey="gameCenter"
      className="gameCenterContainer"
      // renderHeader={({ isActive }) => {
      //   return <Carousel isActive={isActive} />
      // }}
      renderBody={({ isActive }) => {
        return <List isActive={isActive} />
      }}
    />
  )
}

export default React.memo(GameCenter)
