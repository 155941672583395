import { useSelector } from 'react-redux'
import { useMemo } from 'react'

const Hook = () => {
  const rotationBanners = useSelector((state) => state.app.rotationBanners)
  const list = useMemo(() => {
    return rotationBanners.slice(0, 15)
  }, [rotationBanners])
  return list
}

export default Hook
