import React from 'react'
import AspectImage from '@/components/common/Image/AspectImage'

const Gallery = ({
  className,
  gallery,
  onClick,
  imgPlaceholderClass,
  type,
  isEmbedSrc,
  children,
}) => {
  return (
    <AspectImage
      className={className}
      aspectClass={imgPlaceholderClass}
      isVideo={type === 1}
      src={gallery.src}
      alt="singleItemGallery"
      onClick={onClick}
      isEmbedSrc={isEmbedSrc}
      lazy={true}
    >
      {children}
    </AspectImage>
  )
}

export default React.memo(Gallery)
