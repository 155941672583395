import React from 'react'
import classNames from 'classnames'
import Image from '@/components/common/Image/Image'

const AspectImage = ({ aspectClass, className, ...props }) => {
  return (
    <div className={classNames(['responsive-container', className, aspectClass])}>
      <Image alt="AspectImage" {...props} className="transitionImage" />
    </div>
  )
}

export default AspectImage
