import React from 'react'
import { FormattedMessage } from 'react-intl'

const FormattedText = ({ className, text, values, onClick }) => {
  if(!text){
    return null
  }
  return (
    <span className={className} onClick={onClick}>
      <FormattedMessage id={text} values={values} />
    </span>
  )
}

export default FormattedText
