// 设置主播列表
export const SET_PLAY_LIST='SET_PLAY_LIST'

// 设置ip地区
export const SET_GE0_COUNTRY='SET_GE0_COUNTRY'

// 添加app信息缓存
export const ADD_APP_INFO_CACHE='ADD_APP_INFO_CACHE'

// 更新主播列表
export const UPDATE_PLAY_LIST_LIKE='UPDATE_PLAY_LIST_LIKE'

// 更新页码信息
export const UPDATE_PAGE_INFO='UPDATE_PAGE_INFO'

// 设置刷新主播列表
export const SET_TIME_REFRESH='SET_TIME_REFRESH'

// 添加正在缓存app信息标记
export const ADD_APP_INFO_CACHING='ADD_APP_INFO_CACHING'

// 删除正在缓存app信息标记
export const DEL_APP_INFO_CACHING='DEL_APP_INFO_CACHING'

// 更新云游戏信息
export const CHANGE_APP_INFO_CLOUD_INFO='CHANGE_APP_INFO_CLOUD_INFO'

// 设置第一页主播
export const SET_FIRST_PLAY_LIST='SET_FIRST_PLAY_LIST'

// 设置like的timeoutId
export const SET_LIKE_TIMEOUT_ID='SET_LIKE_TIMEOUT_ID'

// 删除like的timeoutId
export const DEL_LIKE_TIMEOUT_ID='DEL_LIKE_TIMEOUT_ID'

// 设置fetchplaylist click的timeoutId
export const SET_FETCH_CLICK_TIMEOUT_ID='SET_FETCH_CLICK_TIMEOUT_ID'

// 删除fetchplaylist click的timeoutId
export const DEL_FETCH_CLICK_TIMEOUT_ID='DEL_FETCH_CLICK_TIMEOUT_ID'

// 设置全部网络列表数据 未登陆
export const SET_ALL_PLAY_LIST_PUB='SET_ALL_PLAY_LIST_PUB'

// 设置全部网络列表数据 已登陆
export const SET_ALL_PLAY_LIST_PRI='SET_ALL_PLAY_LIST_PRI'

// 设置全部网络列表数据 用户使用
export const SET_ALL_PLAY_LIST='SET_ALL_PLAY_LIST'

// 设置全部网络列表数据 用户使用
export const CLEAN_ALL_PLAY_LIST='CLEAN_ALL_PLAY_LIST'

export const SET_APP_INFO_UPDATE_INDEX='SET_APP_INFO_UPDATE_INDEX'
